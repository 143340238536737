import { UAParser } from 'ua-parser-js';

export default function ({ $sensors }) {
  if (process.client) {
    window.onload = () => {
      // 获取浏览器信息
      const { userAgent } = navigator;
      const {
        browser,
        device,
        engine,
        os,
      } = UAParser(userAgent);
      // 假设 Sensors 是全局可用的
      $sensors.track('ua_record', {
        ua_agent: userAgent,
        browser_name: browser.name,
        browser_version: browser.version,
        device_model: device.model,
        device_vendor: device.vendor,
        engine_name: engine.name,
        engine_version: engine.version,
        os_name: os.name,
        os_version: os.version,
      });
    };
  }
}
